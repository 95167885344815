import {
  post,
  postExcel,
  get,
  getWithVersion,
  getWithVersionAndPermission,
  put,
  remove,
  getWithToken,
  postWithToken,
} from "../utils/req";
import qs from "qs";

// const baseURL = "http://127.0.0.1:8000/"
// const baseURL = "http://120.27.209.14:7105/"
// const baseURL = process.env.VUE_APP_CURRENTMODE == 'dev' ? "http://127.0.0.1:8000/" : "http://120.27.209.14:7105/"
let baseURL = "";
let userUrl = "http://120.27.209.14:22910/";
// let userUrl = "http://127.0.0.1:8001/"
console.log("当前调测环境：", process.env.VUE_APP_CURRENTMODE);
if (process.env.VUE_APP_CURRENTMODE == "local") {
  // baseURL = "http://0.0.0.0:7105/"
  baseURL = "http://127.0.0.1:8000/";
} else if (process.env.VUE_APP_CURRENTMODE == "dev") {
  baseURL = "http://120.27.209.14:7105/"
  // baseURL = "https://platform.surbot.cn/pro_api/";
} else if (process.env.VUE_APP_CURRENTMODE == "pro") {
  // baseURL = "http://121.37.143.117:23005/"
  // baseURL = "http://120.27.209.14:7105/";
  baseURL = "https://platform.surbot.cn/pro_api/"; // @@@
  userUrl = "https://platform.surbot.cn/pro_user_api/"; // @@@
  // userUrl = "http://120.27.209.14:7105/";
  // baseURL = "http://120.27.209.14:7105/"
  // baseURL = "http://127.0.0.1:8000/"
  // userUrl = "http://121.37.143.117:23010/"
} else if (process.env.VUE_APP_CURRENTMODE == "cloud") {
  baseURL = "http://139.159.239.166:7105/";
  userUrl = "http://139.159.239.166:22910/";
}
// const baseURL = process.env.VUE_APP_API_URL
// const baseURL = "http://121.37.143.117:23005/"
// const userUrl = "http://192.168.3.16:8000/"
const mapUrl = "http://120.27.209.14:7713/";
const faissUrl = "https://surbot.cn/pro_faiss_api/";

export default {
  // 登录
  login(params) {
    // return post(baseURL +'report/login/', params)
    return post(userUrl + "login/", params, 10000);
  },
  // 注册
  register(params) {
    // return post(baseURL +'report/register/', params)
    return post(userUrl + "register/", params);
  },
  // 获取用户信息
  getUserInfo(params) {
    return getWithVersionAndPermission(
      userUrl + `tech_radar_user_info/?${qs.stringify(params)}`
    );
  },
  //获取用户基本信息
  getUserBaseInfo(params) {
    return get(userUrl + `tech_radar_user_base_info?${qs.stringify(params)}`);
  },
  //改变用户基本信息
  changeUserBaseInfo(params) {
    return put(userUrl + `tech_radar_user_base_info`, params);
  },
  // 修改用户信息
  changeUserInfo(params) {
    return put(userUrl + "user_info/", params);
  },
  // 获取验证码
  getImageCode(params) {
    return getWithVersionAndPermission(userUrl + `image_codes/${params}`);
  },
  // 发送邮箱验证码
  sendEmailCode(params) {
    return getWithVersionAndPermission(
      userUrl + `num_codes/?${qs.stringify(params)}`
    );
  },
  // 找回密码
  changePwd(params) {
    return post(userUrl + `forgotpwd/`, params);
  },
  // 获取板块类别
  getBoard() {
    return get(baseURL + "report/board/");
  },
  // 获取对应板块的公司
  // getCompanyByBoard(params) {
  //     return get(baseURL +`report/company/?${qs.stringify(params)}`)
  // },
  // 获取板块对应公司(patent接口)
  getCompanyByBoard(params) {
    return get(
      baseURL + `report/industry_link_company/?${qs.stringify(params)}`
    );
  },
  // 获取专利信息
  getCompanyBaseInfo(params) {
    return get(
      baseURL + `report/portrait_base_info/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取专利列表
  getPatentList(params) {
    return get(
      baseURL + `report/link_patent_list/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取新闻
  getNews(params) {
    return get(baseURL + `news/news_detail/?${qs.stringify(params)}`, 60000);
  },
  // 获取新闻(限制1000条)
  getNewsLimit(params) {
    return get(
      baseURL + `news/news_detail_limit/?${qs.stringify(params)}`,
      60000
    );
  },
  // 修改新闻极性
  changeNewsPolarity(params) {
    return put(baseURL + "news/news_detail/", params);
  },
  // 删除新闻
  removeNews(params) {
    return remove(baseURL + "news/news_detail", params);
  },
  // 获取新闻热度统计
  getNewsHotCount(params) {
    return get(baseURL + `news/news_hot_count/?${qs.stringify(params)}`, 60000);
  },
  // 获取新闻热度统计
  getNewsHotCountIndustry(params) {
    return get(
      baseURL + `news/news_hot_count_industry/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取新闻关键词
  getNewsKeyword(params) {
    return get(baseURL + `news/news_keyword/?${qs.stringify(params)}`, 15000);
  },
  // 获取新闻关键词
  getNewsKeywordIndustry(params) {
    return get(
      baseURL + `news/news_keyword_industry/?${qs.stringify(params)}`,
      15000
    );
  },
  // 获取新闻五大类统计
  getNewsTypeCount(params) {
    return get(
      baseURL + `news/news_type_count/?${qs.stringify(params)}`,
      15000
    );
  },
  // 获取新闻五大类统计(实时)
  getNewsTypeCountRealTime(params) {
    return get(
      baseURL + `news/news_type_count_realtime/?${qs.stringify(params)}`,
      15000
    );
  },
  // 获取新闻五大类统计(产业标签)
  getNewsTypeCountIndustry(params) {
    return get(
      baseURL + `news/news_type_count_industry/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取新闻极性统计
  getNewsPolarityCount(params) {
    return get(
      baseURL + `news/news_polarity_count/?${qs.stringify(params)}`,
      15000
    );
  },
  // 获取新闻极性统计(实时)
  getNewsPolarityCountRealTime(params) {
    return get(
      baseURL + `news/news_polarity_count_realtime/?${qs.stringify(params)}`,
      15000
    );
  },
  // 获取新闻极性统计(产业标签)
  getNewsPolarityCountIndustry(params) {
    return get(
      baseURL + `news/news_polarity_count_industry/?${qs.stringify(params)}`,
      15000
    );
  },
  // 获取行业统计
  getIndustryCount(params) {
    return get(
      baseURL + `report/link_count_industry/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取企业类型统计
  getTypeCount(params) {
    return get(
      baseURL + `report/link_count_type/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取半年度报告摘要
  getPdfSemiannual(params) {
    return get(baseURL + `report/pdf_semiannual/?${qs.stringify(params)}`);
  },
  // 获取季度报告
  getPdfQuarter(params) {
    return get(baseURL + `report/pdf_quarter/?${qs.stringify(params)}`);
  },
  // 获取年度报告摘要
  getPdfAnnual(params) {
    return get(baseURL + `report/pdf_annual/?${qs.stringify(params)}`);
  },
  // 获取招股书
  getPdfProspectus(params) {
    return get(baseURL + `report/pdf_prospectus/?${qs.stringify(params)}`);
  },
  // 获取半年度报告全文
  getPdfSemiannualFull(params) {
    return get(baseURL + `report/pdf_semiannual_full/?${qs.stringify(params)}`);
  },
  // 获取年度报告全文
  getPdfAnnualFull(params) {
    return get(baseURL + `report/pdf_annual_full/?${qs.stringify(params)}`);
  },
  // 获取公司财务信息
  getFinancialInfo(params) {
    return get(baseURL + `report/financial_info/?${qs.stringify(params)}`);
  },
  // 获取公司产品信息
  getProductInfo(params) {
    return get(baseURL + `report/product_info/?${qs.stringify(params)}`);
  },
  // 获取公司产业标签
  getProductTag(params) {
    return get(baseURL + `report/product_tag/?${qs.stringify(params)}`);
  },
  // 修改(删除)公司产业标签
  changeProductTag(params) {
    return put(baseURL + "report/product_tag/", params);
  },
  // 获取产业链
  getLink(params) {
    return get(baseURL + `report/portrait_link_info/?${qs.stringify(params)}`);
  },
  // 下载pdf
  downloadPdf(params) {
    // return get(baseURL + `report/download/?${qs.stringify(params)}`)
    // window.open(`http://surbot.cn:7105/report/download/?${qs.stringify(params)}`,"_blank")
    // window.open(`http://127.0.0.1:8000/report/download/?${qs.stringify(params)}`,"_blank")
    window.open(
      `http://120.27.209.14:7105/report/pdf/?${qs.stringify(params)}`,
      "_blank"
    );
  },
  // 获取产业关键词
  getIndustryKeyword(params) {
    return get(baseURL + `report/industry_keyword/?${qs.stringify(params)}`);
  },
  // 获取企业关键词(专利词云)
  getCompanyKeyword(params) {
    return get(
      baseURL + `report/company_keyword_cloud/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业列表
  getIndustry(params) {
    return get(baseURL + `report/industry/?${qs.stringify(params)}`, 20000);
  },
  // 获取行业图谱
  getIndustryGraph(params) {
    return get(
      baseURL + `report/industry_graph/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取专利关键词
  getPatentKeyword() {
    return get(baseURL + "report/patent_keyword", 60000);
  },
  // 获取新上市公司列表
  getNewCompany(params) {
    return get(baseURL + `report/new_company/?${qs.stringify(params)}`);
  },
  // 获取公司散点分布
  getCompanyScatt() {
    return getWithVersion(mapUrl + "KGgovern/get_area_company_statistic");
  },
  // 获取公司列表（ES 根据搜索产品）
  getCompanyByProduct(params) {
    return getWithVersion(
      mapUrl + `suppliers_server/es_query_suppliers?product=${params}`
    );
  },
  // 获取公司列表(产业图谱)
  getCompanyByCategory(params) {
    return getWithVersion(
      mapUrl + `suppliers_server/es_query_potential_suppliers?product=${params}`
    );
  },
  // 获取产业链
  getCategoryLink(params) {
    return getWithVersion(
      mapUrl + `suppliers_server/query_chain?product=${params}`
    );
  },
  // 根据产品获取公司列表(mysql)
  getCompanyByProductSql(params) {
    return get(
      baseURL + `report/company_product/?${qs.stringify(params)}`,
      20000
    );
  },
  // 根据产品获取公司列表(es)
  getCompanyByProductEs(params) {
    return get(
      baseURL + `report/es_company_product/?${qs.stringify(params)}`,
      20000
    );
  },
  // 根据产品获取公司在中国的分布散点
  getScatterByProduct(params) {
    return get(
      baseURL + `report/count_province_company/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取产业树
  getIndustryTree(params) {
    return get(
      baseURL + `report/industry_map_tree/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取产业节点公司列表
  getIndustryCompany(params) {
    return get(
      baseURL + `report/industry_map_node/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取公司详情信息(产业图谱)
  getCompanyInfo(params) {
    return get(
      baseURL + `report/industry_map_company/?${qs.stringify(params)}`
    );
  },
  // 获取产业图谱选项
  getIndustryGraphOption() {
    return get(baseURL + `report/industry_graph_option/`, 20000);
  },
  // 获取产业图谱选项(技术雷达特供)
  getIndustryGraphOptionRadar(params) {
    return get(
      baseURL + `report/industry_graph_option_radar/?${qs.stringify(params)}`,
      25000
    );
  },
  // 获取产业风险行业统计
  getNewsIndustryCount() {
    return get(baseURL + `news/news_type_count_news_industry/`, 60000);
  },
  // 获取产业风险关键词
  getNewsIndustryKeyword() {
    return get(baseURL + "news/news_industry_keyword/", 20000);
  },
  // 获取产业专利
  getIndustryPatent(params) {
    return get(
      baseURL + `report/industry_patent/?${qs.stringify(params)}`,
      60000
    );
  },
  // 修改（删除）产业专利标签
  changeIndustryPatentTags(params) {
    return put(baseURL + "report/industry_patent/", params);
  },
  // 获取产业市场简介
  getIndustryIntroduction(params) {
    return get(
      baseURL + `report/industry_introduction/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取专利技术地图分布
  getIndustryPatentScatter(params) {
    return get(
      baseURL + `report/industry_patent_scatter/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取产业风险舆情
  getIndustryRiskNews(params) {
    return get(
      baseURL + `news/industry_risk_news/?${qs.stringify(params)}`,
      60000
    );
  },
  // 删除产业风险舆情
  removeIndustryRiskNews(params) {
    return remove(baseURL + "news/industry_risk_news/", params);
  },
  // 修改产业风险舆情 风险类型
  changeIndustryRiskNews(params) {
    return put(baseURL + "news/industry_risk_news/", params);
  },
  // 修改(删除)风险舆情标签
  changeNewsTag(params) {
    return put(baseURL + "news/news_tag/", params);
  },
  // 获取新闻产业类型列表
  getNewsIndustry(params) {
    return get(baseURL + `news/news_industry/?${qs.stringify(params)}`);
  },
  // 修改风险舆情的产业
  changeRiskNewsIndustry(params) {
    return put(baseURL + "news/news_industry/", params);
  },
  // 获取产业风险月度统计
  getIndustryRiskCount(params) {
    return get(
      baseURL + `news/industry_risk_count/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取产业专利年度统计
  getIndustryPatentCount(params) {
    return get(
      baseURL + `report/industry_patent_count/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取产业专利关键词
  getIndustryPatentKeyword(params) {
    return get(
      baseURL + `report/industry_patent_keyword/?${qs.stringify(params)}`,
      20000
    );
  },
  // 导出风险舆情
  exportIndustryRisk(params) {
    return postExcel(baseURL + "news/news_risk_export/", params, 60000);
  },
  // 获取产业对应公司的专利统计
  getCompanyPatentCount(params) {
    return get(
      baseURL + `report/company_patent_count/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取行业图谱公司列表
  getIndustryGraphCompany(params) {
    return get(
      baseURL + `report/industry_graph_company/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取公司评分
  getCompanyScore(params) {
    return get(
      baseURL + `report/company_score_detail/?${qs.stringify(params)}`,
      120000
    );
  },
  // 获取产业生态位
  getIndustryPosition(params) {
    return get(
      baseURL + `report/industry_position/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取公司基本信息
  getCompanyBase(params) {
    return get(baseURL + `report/company_info/?${qs.stringify(params)}`, 10000);
  },
  // 获取公司招聘信息
  getPostInfo(params) {
    return get(baseURL + `report/post_info/?${qs.stringify(params)}`, 30000);
  },
  // 获取公司招聘信息(按产品)
  getPostInfoNode(params) {
    return get(
      baseURL + `report/post_info_node/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取公司招聘排行
  getPostRank(params) {
    return get(
      baseURL + `report/company_post_rank/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取公司招聘信息详情
  getPostDetail(params) {
    return get(baseURL + `report/post_detail/?${qs.stringify(params)}`);
  },
  // 获取招聘月度排行
  getPostMonthRank(params) {
    return get(
      baseURL + `report/post_month_rank/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取招聘散点分布
  getPostScatter(params) {
    return get(baseURL + `report/post_scatter/?${qs.stringify(params)}`, 60000);
  },
  // 获取招聘岗位统计
  getPostCount(params) {
    return get(baseURL + `report/post_count/?${qs.stringify(params)}`, 30000);
  },
  // 获取公司id（通过公司全称）
  getCompanyId(params) {
    return get(baseURL + `report/radar_company_id/?${qs.stringify(params)}`);
  },
  // 获取公司company_guogao_id（通过公司全称）
  getCompanyGuogaoId(params) {
    return get(
      baseURL + `report/radar_company_guogao_id/?${qs.stringify(params)}`
    );
  },
  // 获取散点bar数据
  getPatentScatter(params) {
    return get(
      baseURL + `report/company_patent_count_per_year/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取专利详情
  getPatentDetail(params) {
    return get(baseURL + `report/patent_detail/?${qs.stringify(params)}`);
  },
  // 获取专利详情（mongo）
  getPatentDetailFromMongo(params) {
    return get(
      baseURL + `report/patent_structuration/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业舆情
  getCompanyNews(params) {
    return get(
      baseURL + `news/industry_company_news/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取产业舆情（根据产品）
  getCompanyNewsByProduct(params) {
    return get(
      baseURL + `news/industry_domain_news/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取专利结构图
  getPatentStructure(params) {
    return get(baseURL + `report/patent_structure/?${qs.stringify(params)}`);
  },
  // 获取产业舆情公司
  getNewsCompany(params) {
    return get(baseURL + `news/news_company/?${qs.stringify(params)}`, 20000);
  },
  // 获取发明人结构
  getInventorStructure(params) {
    return get(
      baseURL + `report/company_patent_inventor_graph/?${qs.stringify(params)}`,
      100000
    );
  },
  // 获取人才TOP统计
  getTopCount(params) {
    return get(
      baseURL + `report/post_skill_word_statistics/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取招聘统计(根据技能需求)
  getPostTrend(params) {
    return get(
      baseURL + `report/job_technology_trend/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取技能需求列表
  getTechList(params) {
    return get(
      baseURL + `report/job_technology_list/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取技术需求统计
  getTechCount(params) {
    return get(
      baseURL + `report/job_technology_count/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取招聘大类统计
  getJobCount(params) {
    return get(
      baseURL + `report/job_technology_job_count/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取公司关系图
  getCompanyRelation(params) {
    return get(
      baseURL + `report/company_patent_company_graph/?${qs.stringify(params)}`,
      100000
    );
  },
  // 关键字搜索公司
  getCompanyList(params) {
    return get(baseURL + `report/company_list/?${qs.stringify(params)}`, 60000);
  },
  // 获取热点事件
  getHotEvent(params) {
    return get(
      baseURL + `report/radar_hot_event/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取含有热点事件的公司
  getHotEventCompany() {
    return get(baseURL + `report/radar_hot_event_company/`);
  },
  // 获取公司专利趋势
  getCompanyPatentTrend(params) {
    return get(
      baseURL + `report/company_patent_trend/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取舆情、招聘、专利相关产品关系图
  getCompanyRelatedProducts(params) {
    return get(
      baseURL + `report/company_related_products/?${qs.stringify(params)}`,
      30000
    );
  },
  // // 获取子公司相关产品关系图
  // getSubCompanyRelatedProducts(params) {
  //     return get(baseURL + `report/sub_company_related_products/?${qs.stringify(params)}`, 30000)
  // },
  // 修改新闻产品判断
  changeNewsRight(params) {
    return put(baseURL + "news/change_news_right/", params);
  },
  // 修改招聘产品判断
  changePostRight(params) {
    return put(baseURL + "report/change_post_right/", params);
  },
  // 修改专利产品判断
  changePatentRight(params) {
    return put(baseURL + "report/change_patent_right/", params);
  },
  // 企业搜索
  companySearch(params) {
    return get(
      baseURL + `report/es_company_search/?${qs.stringify(params)}`,
      1000000
    );
  },
  // 企业搜索(向量)
  companySearchFaiss(params) {
    return get(faissUrl + `advanced_search?${qs.stringify(params)}`, 100000);
  },
  // 下载企业搜索结果
  downloadCompanySearch(params) {
    // return get(baseURL + `report/es_company_search_download/`)
    window.open(
      baseURL + `report/es_company_search_download/?${qs.stringify(params)}`,
      "_blank"
    );
  },
  // 企业搜索详情
  companySearchDetail(params) {
    return get(
      baseURL + `report/es_company_search_detail/?${qs.stringify(params)}`,
      20000
    );
  },
  // 企业搜索详情(新版)
  companySearchDetailNew(params) {
    return get(
      baseURL + `report/company_search_detail/?${qs.stringify(params)}`,
      20000
    );
  },
  // 添加用户收藏
  addUserStar(params) {
    return post(baseURL + "news/user_star/", params);
  },
  // 取消用户收藏
  cancelUserStar(params) {
    return remove(baseURL + "news/user_star/", params);
  },
  // 获取用户收藏
  getUserStar(params) {
    return get(baseURL + `news/user_star/?${qs.stringify(params)}`, 20000);
  },
  // 修改高级搜索标注
  changeCompanySearchMark(params) {
    return put(baseURL + "report/company_search_mark/", params);
  },
  // 获取热点事件
  getHotNews(params) {
    return get(baseURL + `news/news_hot/?${qs.stringify(params)}`, 60000);
  },
  //根据公司名获取产业舆情
  getNewsByCompany(params) {
    return get(
      baseURL + `news/industry_company_news/?${qs.stringify(params)}`,
      60000
    );
  },
  //获取产业人才信息
  getIndustryTalents(params) {
    return get(
      baseURL + `report/industry_inventor/?${qs.stringify(params)}`,
      60000
    );
  },
  //获取产业人才合作者信息
  getCollaborator(params) {
    return get(
      baseURL + `report/inventor_collaborator/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取企业专利标签
  getCompanyPatentTags(params) {
    return get(baseURL + `report/company_patent_tags/?${qs.stringify(params)}`);
  },
  // 获取公司产业节点标签
  getCompanyDomainTags(params) {
    return get(baseURL + `report/company_domain_tags/?${qs.stringify(params)}`);
  },
  // 获取产业节点的根节点
  getDomainNodeRoot(params) {
    return get(baseURL + `report/domain_node_root/?${qs.stringify(params)}`);
  },
  // 获取司法公告
  getEventRisk(params) {
    return get(baseURL + `news/event_risk/?${qs.stringify(params)}`, 15000);
  },
  // 获取产业人才河流图数据
  getInventorRiver(params) {
    return get(
      baseURL + `report/inventor_patent_ipc/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业人才的专利
  getInventorPatent(params) {
    return get(
      baseURL + `report/inventor_patent/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取用户订阅
  getUserSubscribe(params) {
    return get(
      baseURL + `report/user_subscribe/?${qs.stringify(params)}`,
      30000
    );
  },
  // 添加订阅
  addUserSubscribe(params) {
    return post(baseURL + "report/user_subscribe/", params);
  },
  // 取消订阅
  cancelSubscribe(params) {
    return remove(baseURL + "report/user_subscribe/", params);
  },
  // 获取用户信息（用于url登录）
  getUserInfoByToken(access_token) {
    return getWithToken(userUrl + "token_login", 10000, access_token);
  },
  // 获取产业前沿机构
  getIndustryFrontierOrganization(params) {
    return get(
      baseURL +
      `report/industry_frontier_organization/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业前沿关键词
  getIndustryFrontierWordClouds(params) {
    return get(
      baseURL + `report/industry_frontier_word_clouds/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取产业前沿新闻
  getIndustryFrontierDetail(params) {
    return get(
      baseURL + `report/industry_frontier_detail/?${qs.stringify(params)}`,
      60000
    );
  },
  // 获取项目投资地区分布
  getIndustryInvestmentRegion(params) {
    return get(
      baseURL + `report/industry_investment_region/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取项目投资资讯
  getIndustryInvestmentDetail(params) {
    return get(
      baseURL + `report/industry_investment_detail/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业风险标签
  getIndustryRiskTags(params) {
    return get(
      baseURL + `report/industry_risk_tags/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业风险预警
  getIndustryRisk(params) {
    return get(
      baseURL + `report/industry_risk/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业政策
  getIndustryPolicy(params) {
    return get(
      baseURL + `report/industry_policy/?${qs.stringify(params)}`,
      20000
    );
  },
  // 产业链搜索
  industryNodeSearch(params) {
    return get(
      baseURL + `report/industry_node_search/?${qs.stringify(params)}`,
      20000
    );
  },
  // 获取产业链介绍
  getIndustryDesc(params) {
    return get(
      baseURL + `report/industry_desc/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取最近的根节点
  findRootNode(params) {
    return get(
      baseURL + `report/find_root_node/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取子公司
  getSubCompany(params) {
    return get(
      baseURL + `report/sub_company_info/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取子公司区域分布
  getSubCompanyRegion(params) {
    return get(
      baseURL + `report/sub_company_region/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取子公司标签
  getSubCompanyLabel(params) {
    return get(
      baseURL + `report/sub_company_label/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取项目布局
  getCompanyInvestmentRegion(params) {
    return get(
      baseURL + `report/company_investment_region/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取上下游公司
  getCompanyLink(params) {
    return get(baseURL + `report/company_link/?${qs.stringify(params)}`, 10000);
  },
  // 获取新闻统计
  getNewsCount(params) {
    return get(baseURL + `news/news_count/?${qs.stringify(params)}`, 30000);
  },
  // 获取子公司新闻统计
  getSubCompanyNewsCount(params) {
    return get(
      baseURL + `news/sub_company_news_count/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取公司信息
  getCompanyNewsByShortName(params) {
    return get(baseURL + `news/company_news/?${qs.stringify(params)}`, 10000);
  },
  // 绑定微信账户
  bindWechat(params) {
    return getWithVersionAndPermission(
      userUrl + `web_bind_wechat/?${qs.stringify(params)}`,
      10000
    );
  },
  // 注册账户
  wechatRegister(params) {
    return post(userUrl + "wechat_register/", params);
  },
  // 退出(点链清除token)
  removeTokenDianLian(params, type) {
    if (type == "dev") {
      return postWithToken(
        "https://uat-gateway.dianliantech.com/api-uaa/oauth/remove/token",
        params
      );
    } else if (type == "pro") {
      return postWithToken(
        "https://gateway.dianliantech.com/api-uaa/oauth/remove/token",
        params
      );
    }
  },
  // 获取公司专利河流图
  getCompanyPatentRiver(params) {
    return get(
      baseURL + `report/company_patent_river/?${qs.stringify(params)}`,
      30000
    );
  },
  // 获取公司专利总数
  getCountCompanyPatent(params) {
    return get(
      baseURL + `report/count_company_patent/?${qs.stringify(params)}`,
      10000
    );
  },
  // 获取项目布局相关新闻
  getProjectNews(params) {
    return get(
      baseURL + `report/company_project_news/?${qs.stringify(params)}`,
      30000
    );
  },
  // 新增自定义节点
  addCustomNode(params) {
    return post(baseURL + "report/custom_node/", params);
  },
  // 删除自定义节点
  deleteCustomNode(params) {
    return remove(baseURL + "report/custom_node/", params);
  },
  // 获取自定义节点
  getCustomNode(params) {
    return get(baseURL + `report/custom_node/?${qs.stringify(params)}`, 10000);
  },
  // 自定义节点编辑-搜索公司
  searchCustomCompany(params) {
    return get(
      baseURL + `report/custom_search_company/?${qs.stringify(params)}`,
      60000
    );
  },
  // 自定义节点添加公司
  customAddCompany(params) {
    return post(baseURL + "report/custom_link_company/", params);
  },
  // 修改自定义节点名称
  changeNodeName(params) {
    return put(baseURL + "report/custom_node/", params);
  },
  // 激活试用
  activePerm(params) {
    return put(userUrl + "user_activate_perm", params);
  },
  // 获取试用状态
  getActiveStatus(params) {
    return getWithVersionAndPermission(
      userUrl + `user_activate_perm?${qs.stringify(params)}`
    );
  },
  // 获取企业类型数据
  getCompanyType(params) {
    return get(baseURL + "report/custom_company_type/", params);
  },
  // 自定义节点批量添加关联企业
  customAddFileCompany(params) {
    return post(baseURL + "report/custom_link_file_company/", params, 60000);
  },
};

/* eslint-disable no-unused-vars */
import axios from "axios";
import router from "@/router";
import cookie from "@/utils/common.js";
import { message } from "./resetMessage";
import Vue from "vue";

let version = Vue.prototype.version;
// axios 配置
axios.defaults.timeout = 30000;
axios.defaults.headers["Content-Type"] = "application/json";
// 根据环境更改接口地址
// axios.defaults.baseURL = process.env.NODE_ENV=='dev' ? 'http://surbot.cn:7717/' : 'http://surbot.cn:7716/'

// axios.defaults.baseURL = 'http://127.0.0.1:8000';
// axios.defaults.baseURL = 'http://surbot.cn:7105';

// axios.interceptors.response.use((res) => {
//     return res
// }, (error) => {
//     console.log("axios", error)
// })
const CancelToken = axios.CancelToken;
let sources = []; // 定义数组用于存储每个ajax请求的取消函数及对应标识

// 禁止重复调用的接口列表（即使参数不同）
let abandonRetryApi = [
  "es_company_search_detail",
  "user_star",
  "industry_company_news",
  // "news_hot"
];
// 允许同一时间多次调用的接口列表
let allowRetryApi = [
  "industry_map_tree",
  "company_related_products",
  "industry_graph_option_radar",
];
/**
 * 请求防抖当一个url地址被请求多次就会取消前一次请求
 */
let removeSource = (config) => {
  // console.log("config", config)
  for (let source in sources) {
    // if (config.url.indexOf("?") != -1) {
    //     config.url_main = config.url.split("?")[0]
    // }
    // 当多次请求相同时，取消之前的请求
    // console.log("sources[source].umet", sources[source].umet)
    // if (sources[source].umet === config.url + '&' + config.method) {
    let url_arr = config.url.split("/");
    if (allowRetryApi.indexOf(url_arr[url_arr.length - 2]) == -1) {
      // console.log("url_arr", url_arr[url_arr.length-2])
      if (config.url.indexOf("?") != -1) {
        if (abandonRetryApi.indexOf(url_arr[url_arr.length - 2]) != -1) {
          if (sources[source].umet.indexOf(config.url.split("?")[0]) != -1) {
            sources[source].cancel("取消请求");
            sources.splice(source, 1);
          }
        } else {
          if (sources[source].umet === config.url + "&" + config.method) {
            sources[source].cancel("取消请求");
            sources.splice(source, 1);
          }
        }
      }
    }
  }
};


/**
 * 请求拦截器
 */
axios.interceptors.request.use(
  (config) => {
    removeSource(config);
    config.cancelToken = new CancelToken((c) => {
      // 将取消函数存起来
      sources.push({ umet: config.url + "&" + config.method, cancel: c });
    });
    return config;
  },
  (error) => {
    // console.log("error", error)
    // if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout')!=-1) {
    //     message.error("请求超时，请重试！")
    // }
    return Promise.reject(error);
  }
);

// 响应拦截器
// axios.interceptors.response.use(config => {
//     if (config.data.statusCode >= 3000) {
//         console.error("xxxxxxx")
//     }
//     removeSource(config.config)

//     return config.data;
// }, error => {
//         if (!error.response) return
//         return Promise.reject(error.response)
//     }
// )

export function get(url, timeout) {
  if (!cookie.getCookie("permissions")) {
    message.warning("用户信息已过期，请重新登录！");
    window.location.reload();
    router.push("/");
  }
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      timeout: timeout ? timeout : 30000,
      headers: {
        // Authorization: cookies.get('token')
        version: version,
        "sw-username": cookie.getCookie("account"),
        "sw-token": cookie.getCookie("token"),
        "system-token": cookie.getCookie("system_token"),
      },
    })
      .then((res) => {
        // console.info("TOKEN", cookie.getCookie("token"))
        if (res.data.status == "202") {
          message.warning(res.data.msg + ", 3s后为您自动刷新版本!");
          router.push("/");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        if (res.data.status == "401") {
          message.warning(res.data.msg);
          window.location.reload();
          router.push("/");
        }
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
        // console.log(error.response)
        console.log("error", error.toString());
        if (error.toString().indexOf("timeout") != -1) {
          message.error("请求超时，请重试！");
        }
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
      });
  });
}

export function getWithToken(url, timeout, access_token) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      timeout: timeout ? timeout : 10000,
      headers: {
        "access-token": access_token,
      },
    })
      .then((res) => {
        // console.info("TOKEN", cookie.getCookie("token"))
        if (res.data.status == "202") {
          message.warning(res.data.msg + ", 3s后为您自动刷新版本!");
          router.push("/");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        if (res.data.status == "401") {
          message.warning(res.data.msg);
          window.location.reload();
          router.push("/");
        }
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
        // console.log(error.response)
        console.log("error", error.toString());
        // if (error.toString().indexOf("timeout") != -1) {
        //     message.error("请求超时，请重试！")
        // }
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
      });
  });
}

export function getWithVersion(url, timeout) {
  if (!cookie.getCookie("permissions")) {
    message.warning("权限已刷新，请重新登录！");
    router.push("/");
  }
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      timeout: timeout ? timeout : 5000,
    })
      .then((res) => {
        if (res.data.status == "202") {
          message.warning(res.data.msg + ", 3s后为您自动刷新版本!");
          router.push("/");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response);
        console.log(error.response);
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
      });
  });
}

export function getWithVersionAndPermission(url, timeout) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      timeout: timeout ? timeout : 5000,
    })
      .then((res) => {
        if (res.data.status == "202") {
          message.warning(res.data.msg + ", 3s后为您自动刷新版本!");
          router.push("/");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response);
        console.log(error.response);
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
      });
  });
}

export function post(url, params, timeout) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data: params,
      timeout: timeout ? timeout : 5000,
      // headers: {
      //     Authorization: cookies.get('token')
      // }
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response);
        console.log(error.response);
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
        // console.log(error.response)
        if (error.response) {
          // alert('error');
        } else {
          // alert('ELSE');
        }
      });
  });
}

export function postExcel(url, params, timeout) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data: params,
      timeout: timeout ? timeout : 5000,
      responseType: "blob",
      // headers: {
      //     Authorization: cookies.get('token')
      // }
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error.response);
        console.log(error.response);
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
        // console.log(error.response)
        if (error.response) {
          // alert('error');
        } else {
          // alert('ELSE');
        }
      });
  });
}

export function postWithToken(url, params, timeout) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url,
            data: params,
            timeout: timeout ? timeout:5000,
            headers: {
                Authorization: "bearer " + cookie.getCookie("dianlian_token")
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error.response);
                console.log(error.response)
                // if (error.response.status == 403) {
                //     router.push({path: '\login'})
                // }
                // console.log(error.response)
                if (error.response) {
                    // alert('error');
                } else {
                    // alert('ELSE');
                }
            });
    });
}

export function put(url, params, timeout) {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url,
      data: params,
      timeout: timeout ? timeout : 5000,
      // headers: {
      //     Authorization: cookies.get('token')
      // }
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response);
        console.log(error.response);
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
        if (error.response) {
          // alert('error');
        } else {
          // alert('ELSE');
        }
      });
  });
}

export function remove(url, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: "delete",
      url,
      data: params,
      // headers: {
      //     Authorization: cookies.get('token')
      // }
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response);
        console.log(error.response);
        // if (error.response.status == 403) {
        //     router.push({path: '\login'})
        // }
        if (error.response) {
          alert("error");
        } else {
          alert("ELSE");
        }
      });
  });
}
